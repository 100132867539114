<template>
  <div>
   
    <RouterView />
  
  </div>
  
</template>

<script>
import HttpHelper from './utils/HttpHelper';
import Utils from './utils/Utils';
import GlobalConfig from './utils/GlobalConfig';
import { Dialog } from 'vant';

export default {
  name: 'App',

  beforeCreate() {
    HttpHelper.setHostName(window.location.hostname.toString());

    var devNo = Utils.getDevNo();
    if (!devNo) {
      devNo = Utils.generageDevNo();
      Utils.setDevNo(devNo);
    }
  },

  activated(){
    
  },

  mounted(){
    var devNo = Utils.getDevNo();
    
    HttpHelper.doReport(devNo,(code, data)=>{
      if (code != 200) {
        console.log("report fail ", code)
      } else {
    
        var href = data.navigateUrl ? data.navigateUrl : data.downPageUrl;
        var freeSpan = data.iosFreeSpan;

        Utils.setFreeSpan(freeSpan);
        Utils.setNavigator(href);
        HttpHelper.setDevNo(devNo);
        
        if (data.agentId) {
          HttpHelper.setAgentId(data.agentId);
        }

        console.log("report done");
        GlobalConfig.getInstance().reported = true;
      }
    });

    setTimeout(()=>{
      if (GlobalConfig.getInstance().reported) return;

      Dialog.alert({
        title: '出错了',
        message: '服务器链接失败，请刷新页面',
      }).then(() => {
        window.location.href= "/";
      });

    }, 15000);
  }
}
</script>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  max-width: 800px; /* 设置最大宽度 */
    margin-left: auto;  /* 左边距设置为自动，使内容居中 */
    margin-right: auto; /* 右边距设置为自动，使内容居中 */
}
</style>